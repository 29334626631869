import React from 'react';

// Stylesheets
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

// Third Party Imports
import { Carousel } from 'react-responsive-carousel';

// Images
import ad1 from '../../assets/images/info-1.jpg';
import ad2 from '../../assets/images/info-4.jpeg';

function AdCarousel() {
  return (
    <Carousel>
      <div className=''>
          <img src={ad1} alt="805 Mattress Pros Sale, Free Local Delivery, Clearance Sale!" />
      </div>
      <div className='w-6/12 ml-auto mr-auto'>
          <img src={ad2} alt="805 Mattress Pros Veteran's Day Mattress Sale!" />
      </div>
  </Carousel>
  )
}

export default AdCarousel;