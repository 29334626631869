import React, { useEffect } from 'react';

// Stylesheets
import 'aos/dist/aos.css';

// Imports
import Aos from 'aos';

function ContactInfo() {

   // Animations
   useEffect(() => {
    // 2 second animation completeness
    Aos.init({ duration: 1500 });
  }, []);

  return (
        <div>
            <div className=" px-6 pt-10">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                ADDRESS
              </h2>
              <a 
                href="https://www.google.com/maps/place/805+Mattress+Pros/@34.2784585,-118.7964324,17z/data=!3m1!4b1!4m5!3m4!1s0x80e82e948da55b05:0xe2af8b59d1548153!8m2!3d34.2784585!4d-118.7942437"
                target="_blank"
                rel="noreferrer"
                className="mt-1 text-newRed">185 E Easy St, Simi Valley, CA 93065</a>
            </div>
            <div className=" px-6 mt-4">
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs">
                EMAIL
              </h2>
              <a
                href="mailto:1mattresspros@gmail.com"
                className="text-newRed leading-relaxed"
              >
                1mattresspros@gmail.com
              </a>
              <h2 className="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">
                PHONE
              </h2>
              <a 
                href="tel:805-522-1344"
                className="leading-relaxed text-newRed">805-522-1344</a>
            </div>
        </div>
  );
}

export default ContactInfo; 